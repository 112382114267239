import React from "react";
import { RingLoader } from "react-spinners";

function FallbackSpinner() {

  return (
     <div className="spinner-container">
      <RingLoader className="loader" color="#fa8c16" size="100px" />
    </div>
  );
}

export default FallbackSpinner;
