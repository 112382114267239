// import Cookies from "js-cookie";
// var CryptoJS = require("crypto-js");
import CryptoJS from "crypto-js";
import { createFileName } from 'use-react-screenshot/dist/index.es';
import * as htmlToImage from "html-to-image";
import moment from "moment";
import { toggleDotsOption } from "../store/actions/insurance/analytics.action";
import { copyImageToClipboard } from 'copy-image-clipboard';
import { toast } from 'react-toastify';

// Common Object Update
export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const takeScreenshot = async (node) => {
  const dataURI = await htmlToImage.toPng(node);
  return dataURI;
};

export const DownloadImg = (image, { name = "analytics-" + moment().unix(), extension = 'png' } = {}) => {
  const file = document.createElement('a')
  file.href = image
  file.download = createFileName(extension, name);
  file.click()
}

export const getImage = (data) => takeScreenshot(data.current).then(DownloadImg)

export const copyImg = async (ref) => {

  const base64URL = await takeScreenshot(ref.current)

  copyImageToClipboard(base64URL)
    .then(() => {
      toast.success('Image Copied Successfully', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    })
    .catch(() => {
      toast.error('The selected report has not been successfully copied yet. Please try again.', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    })

}

// Encrypt Cookie
// export const cookieEncryption = (key, data) => {
//   if (key && data) {
//     let ciphertext = CryptoJS.AES.encrypt(
//       JSON.stringify(data),
//       "6b1fadf85275cbc4ebbd4b3225e1698f1a549561b93a0a8262ba343a63d9679e"
//     ).toString();
//     Cookies.set(key, ciphertext, { expires: 7, path: "" });
//   }
// };

// Decrypt Cookie
// export const cookieDecryption = (key) => {
//   const data = Cookies.get(key);
//   let decryptedData = null;
//   if (key && data) {
//     let bytes = CryptoJS.AES.decrypt(data, "6b1fadf85275cbc4ebbd4b3225e1698f1a549561b93a0a8262ba343a63d9679e");
//     decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//   }
//   return decryptedData;
// };

// Encrypt 
export const encryption = (data) => {
  if (data) {
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(data), process.env.REACT_APP_CRYPTO_KEY
    ).toString();
    return encodeURIComponent(ciphertext);
  }
};

// Decrypt localStorage
export const decryption = (data) => {
  let decryptedData = null;
  if (data) {
    let bytes = CryptoJS.AES.decrypt(decodeURIComponent(data), process.env.REACT_APP_CRYPTO_KEY);
    decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return decryptedData;
};

// Encrypt localStorage
export const localStorageEncryption = (key, data) => {
  if (key && data) {
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(data), process.env.REACT_APP_CRYPTO_KEY
    ).toString();
    sessionStorage.setItem(key, ciphertext);
  }
};

// Decrypt localStorage
export const localStorageDecryption = (key) => {
  const data = sessionStorage.getItem(key);
  let decryptedData = null;
  if (key && data) {
    let bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_CRYPTO_KEY);
    decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return decryptedData;
};

