import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const PublicRoute = ({ component: RouteComponent, title }) => {

  return (
    <>
      <Helmet>
        {/* <title>{title}</title> */}
        <title>Hive Analytics Dashboard </title>
      </Helmet>
      <RouteComponent />
    </>
  )
};

PublicRoute.propTypes = {
  Component: PropTypes.func,
  title: PropTypes.string,
  restricted: PropTypes.bool
};

export { PublicRoute };