import React from 'react';
import { ToastContainer } from 'react-toastify';

function Toaster() {
    return (
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            pauseOnHover
            // toastStyle={{ backgroundColor: "#FFA91E", color: 'white' }}
        />
    )
}

export default Toaster