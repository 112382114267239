import React from "react";
import { useSelector } from "react-redux";
import {  RingLoader } from "react-spinners";

function Spinner() {

  const { loader } = useSelector((state) => state.analytics);

  return (
    loader && <div className="spinner-container">
      <RingLoader className="loader" color="#fa8c16" size="100px" />
    </div>
  );
}

export default Spinner;
