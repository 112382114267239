import { lazy } from 'react';

const LandingPage = lazy(() => import('../../views/LandingPage/LandingPage'));

const Error404 = lazy(() => import('../../views/Error404/Error404'));

export const PublicRouteList = [

    // Home
    { path: "/analytics", element: LandingPage, restricted: false, title: "Home" },

    // Error 404
    { path: "*", element: Error404, restricted: false, title: "404" },

]