import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PublicRoute } from "./PublicRoute";
import { PublicRouteList } from "./data/PublicRouteList";

function RoutesComponent() {

  return (
    <BrowserRouter>
      <Routes>
        {/* PublicRoutes */}
        {PublicRouteList?.map(({ element, path, restricted, title }) => (
          <Route
            key={title}
            path={path}
            element={
              <PublicRoute
                component={element}
                title={title}
                restricted={restricted}
              />
            }
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default RoutesComponent;
