import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import analyticsReducer from './reducers/insurance/analytics.reducer'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const rootReducer = combineReducers({
    analytics: analyticsReducer
});

export const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));